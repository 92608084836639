import { connect } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";

const PrivateRoutes = ({
  isAuthenticated,
  user,
  allowedRoles,
  verified,
  id,
}) => {
  if (verified === false) return <Navigate to={`/login/otp?comp=${id}`} />;
  return allowedRoles?.includes(user?.roleId) ? (
    <Outlet />
  ) : isAuthenticated ? (
    <Navigate to="/unauthorized" />
  ) : (
    <Navigate to="/login" />
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.login?.isAuthenticated,
  user: state.login?.auth?.user
    ? state.login?.auth?.user
    : state.login?.auth?.company,
  verified: state.login?.auth?.company?.verified,
  id: state.login?.auth?.company?.id,
});

export default connect(mapStateToProps, null)(PrivateRoutes);
