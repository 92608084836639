import React from "react";

const TermsOfUse = () => {
  return (
    <div className="tou-container">
      <div className="top-image-container">
        <div className="background">
          <div className="terms-of-use-title">
            <span className="title">Head2Rent</span>
            <span className="terms-of-use">Användarvillkor</span>
          </div>
        </div>
      </div>
      <div className="tou-content">
        <div className="tou-desc">
          <span>
            Vi rekommenderar att läsa igenom alla villkor innan användning av
            tjänsten.
          </span>
        </div>
        <div className="tou-title">ANVÄNDARVILLKOR PÅ HEAD2RENT AB:</div>
        <div className="tou-title-description">
          Som användare till Head2Rent AB behöver du följa våra användarvillkor.
          Läs igenom villkoren och kontakta oss om det är någonting som du är
          osäker över. Dessa villkor uppdaterades senast den 1 december 2022 och
          gäller samtliga användare som per denna dag använder vår tjänst.
        </div>
        <div className="divider"></div>
        <div className="terms">
          <div className="left-column">
            <div className="term">
              <span className="term-title">
                Allmän beskrivning av Head2Rent, villkoren och tjänsten
              </span>
              <ol>
                <li>
                  Head2Rent AB tillhandahåller en marknadsplats för dig som vill
                  hyra ut eller hyra in personal (den som prenumererar på vår
                  tjänst benämns hädanefter som ”Användare”) och ger dig
                  möjligheten att digitalt hitta personal för rätt uppdrag hos
                  andra Användare eller hyra ut din personal till andra
                  Användare.
                </li>
                <li>
                  Användare kommer att ges möjligheten att erbjuda sin personal
                  till önskat arvode. Den Användare som hyr in personal av en
                  annan part ska inte åta sig något ansvar gällande den
                  anställde. Den Användare som hyr in personal av en annan part
                  åtar sig att acceptera det arvode anges (”Tjänsten”).
                </li>
                <li>
                  Genom att använda Tjänsten godkänner du följande villkor och
                  förbinder dig att följa dem. Du som registrerar en juridisk
                  person intygar att du har behörighet att binda företaget till
                  dessa villkor.
                </li>
                <li>
                  För tillhandahållandet av Tjänsten ska Användare erlägga en
                  månatlig ersättning i form av en prenumeration (”Avgiften”).
                </li>
                <li>
                  Som Användare av Tjänsten godkänner du härmed att:
                  <ul style={{ listStyleType: "none", marginLeft: "20px" }}>
                    <li>a) Head2Rent inte är part i något avtal;</li>
                    <li>
                      b) Head2Rent inte åter sig något förmedlingsuppdrag;
                    </li>
                    <li>
                      c) Head2Rent inte är rekryteringskonsult,
                      rekryteringsförmedlare, eller annan form av förmedlare,
                      agent eller liknande aktör av personal samt att Head2Rent
                      inte har några åtaganden eller förpliktelser som åligger
                      en sådan aktör; och att
                    </li>
                    <li>
                      d) Head2Rent inte äger, administrerar eller förvaltar
                      Användare, varken direkt eller indirekt.
                    </li>
                  </ul>
                </li>
                <li>
                  Annons som publiceras hos Head2Rent kommer enbart att vara
                  synlig hos Head2Rent.
                </li>
              </ol>
            </div>
            <div className="term">
              <span className="term-title">Personuppgifter</span>
              <ol>
                <li>
                  För att kunna tillhandahålla Tjänsten behöver vi samla in och
                  behandla vissa personuppgifter om dig som företrädare för
                  Användaren och om era anställda. I vår integritetspolicy finns
                  information om vilka uppgifter vi samlar in, hur vi använder
                  personuppgifterna och vilka möjligheter och rättigheter den
                  registrerade har.
                </li>
              </ol>
            </div>
            <div className="term">
              <span className="term-title">Annonsering hos Head2Rent</span>
              <ol>
                <li>
                  För att en anställd ska få annonseras på Head2Rent måste
                  Head2Rents regler för annonsering efterlevas.
                </li>
                <li>
                  Head2Rents regler för annonsering meddelas av Head2Rent och
                  lämnas genom information som finns på Head2Rents hemsida.
                  Head2Rent gör inte anspråk på att, av Head2Rent, lämnad
                  information, med avseende på vad som kan anses vara olagligt
                  eller otillbörligt och således inte får förekomma vid
                  annonsering, är komplett eller uttömmande. Informationen som
                  lämnas är endast exemplifierande.
                </li>
                <li>
                  Den som annonserar på Head2Rent ansvarar på egen hand för att
                  annonsen inte strider mot gällande lagar och regler.
                </li>
                <li>
                  Head2Rent förbehåller sig rätten att granska samtliga konton
                  och annonser samt att neka eller avlägsna en annons som bryter
                  mot Head2Rents regler, tredje parts upphovsrätt, eller mot
                  annan rättslig reglering. Slumpvisa kontroller genomförs
                  löpande. Head2Rent förbehåller sig rätten att stänga av
                  Användare som inte medverkar till sådan kontroll.
                </li>
                <li>
                  Genom att tillhandahålla andra Användare er personal
                  garanterar du att det:
                  <ul style={{ listStyleType: "none", marginLeft: "20px" }}>
                    <li>a) har förmedlats personalen;</li>
                    <li>
                      b) är förenligt med avtal och förpliktelser till
                      personalen;
                    </li>
                    <li>
                      c) är förenligt med tillämpliga lagar, föreskrifter,
                      myndighetsbeslut, domstolsbeslut och liknande.
                    </li>
                  </ul>
                </li>
                <li>
                  När den inhyrande parten har bekräftat personalinhyrningen
                  åtar sig den uthyrande parten att inte ensidigt höja timtaxan
                  eller på annat sätt ändra på villkoren.
                </li>
              </ol>
            </div>
            <div className="term">
              <span className="term-title">Inhyrande parts skyldigheter</span>
              <ol>
                <li>
                  Det åligger den inhyrande parten att erlägga betalning enligt,
                  av den uthyrande parten, utställd faktura.
                </li>{" "}
              </ol>
            </div>
            <div className="term">
              <span className="term-title">Otillåten användning</span>
              <ol>
                <li>
                  I samband med användande av Head2Rents hemsida och Tjänsten
                  åtar du dig att inte:
                  <ul style={{ listStyleType: "none", marginLeft: "20px" }}>
                    <li>
                      a) bryta mot lag, förordning, domstolsbeslut eller
                      myndighetsbeslut;
                    </li>
                    <li>
                      b) använda manuella eller automatiska programvaror,
                      maskiner, algoritmer, tekniska hjälpmedel eller annat
                      förfarande för att genomföra en s.k. ”scraping”,
                      ”crawline” eller ”spidering” av någon internetsida eller
                      program som är tillgängligt genom, eller i samband med,
                      Head2Rents hemsida eller Tjänsten;
                    </li>
                    <li>
                      c) använda Head2Rents hemsida, Tjänsten eller material
                      hänförligt till dessa för kommersiellt eller annat bruk på
                      annat sätt än vad som uttryckligen tillåts enligt
                      Användarvillkoren;
                    </li>
                    <li>
                      d) kopiera, lagra eller på annat sätt få tillgång till
                      information på Head2Rents hemsida eller genom Tjänsten på
                      annat sätt eller med annat syfte än vad som uttryckligen
                      tillåts enligt dessa villkor;
                    </li>
                    <li>
                      e) försöka skada eller påverka Head2Rents hemsida eller
                      Tjänsten exempelvis genom användande av virus eller annan
                      programvara;
                    </li>
                    <li>
                      f) använda Head2Rents hemsida eller Tjänsten för att
                      distribuera s.k. ”spam” eller reklam;
                    </li>
                    <li>
                      g) använda eller sprida material som finns att tillgå på
                      Head2Rents hemsida eller genom Tjänsten, användares
                      identitet, personuppgifter eller annan information som är
                      tillgänglig via Head2Rents hemsida eller Tjänsten, på
                      annat sätt än såsom uttryckligen tillåts enligt dessa
                      villkor;
                    </li>
                    <li>
                      h) kontakta Användare för att uppmana dessa att ansluta
                      sig till eller stödja tredje mans tjänster eller
                      webbplatser som konkurrerar med Head2Rent;
                    </li>
                    <li>
                      i) kontakta Användares personal i syfte att aktivt
                      rekrytera dess Personal.
                    </li>
                    <li>
                      j) uppge inkorrekt, icke-fullständig eller missvisande
                      information vid användande av Head2Rents hemsida eller
                      Tjänsten;
                    </li>
                    <li>
                      k) använda Head2Rents hemsida eller Tjänsten för att
                      publicera eller förmedla pornografi, politiska åsikter,
                      stötande material eller annat material som Head2Rent
                      finner olämpligt.
                    </li>
                  </ul>
                </li>
              </ol>
            </div>
            <div className="term">
              <span className="term-title">Ansvarsbegränsning</span>
              <ol>
                <li>
                  Head2Rent garanterar inte en kontinuerlig, oavbruten eller
                  säker tillgång till Tjänsten. Driften av Head2Rents hemsida
                  kan komma att störas av ett antal faktorer utanför Head2Rents
                  kontroll och Head2Rent ställer inga garantier gällande
                  Head2Rents hemsidas funktion eller tillgänglighet.
                </li>
                <li>
                  Head2Rent kan inte hållas ansvarigt för skador som direkt
                  eller indirekt orsakas på grund av användning av Tjänsten.
                </li>
                <li>
                  Head2Rent utgör ingen part i förhållandet mellan den ut- och
                  inhyrande parten och ansvarar inte för respektive parts
                  annonser.
                </li>
                <li>
                  Head2Rent ansvarar inte för skada som beror på uteblivna eller
                  fördröjda annonssvar eller felaktig information i
                  annonstexten.
                </li>
                <li>
                  Head2Rent ansvarar inte för de fall en Användare orsakas skada
                  på grund av att en annan Användare inte efterlever dessa
                  villkor.
                </li>
              </ol>
            </div>
            <div className="term">
              <span className="term-title">
                Tillämplig lag och tvistelösning
              </span>
              <ol>
                <li>
                  Svensk lag ska tillämpas på Tjänsterna och villkoren. Tvister
                  avseende dessa villkor ska avgöras av svensk allmän domstol.
                </li>
                <li>
                  Om du vill klaga på Head2Rents tjänst kan du också vända dig
                  till Allmänna reklamationsnämnden, läs mer på{" "}
                  <a
                    href="https://www.arn.se"
                    target="_blank"
                    style={{ textDecoration: "underline", color: "blue" }}
                    rel="noreferrer"
                  >
                    {" "}
                    www.arn.se.
                  </a>
                </li>
              </ol>
            </div>
          </div>
          <div className="right-column">
            <div className="term">
              <span className="term-title">
                Förutsättningar för användning av Tjänsten
              </span>
              <ol>
                <li>
                  Tjänsten får endast användas för personal som är direkt
                  anställda hos Användare.
                </li>
                <li>
                  Tjänsten kan endast användas av juridiska personer med ett
                  svenskt registreringsnummer. Du som vill använda Tjänsten
                  måste vara en behörig företrädare för en juridisk person.
                </li>
                <li>
                  För att Head2Rent ska kunna tillhandahålla Tjänsten förutsätts
                  att Användare har uppfyllt av Head2Rent uppställda krav.
                  Head2Rent förbehåller sig rätten att inte tillhandahålla
                  Tjänsten till personer eller företag som har uppgivit osanna
                  uppgifter, missbrukar eller har missbrukat Head2Rents
                  tjänster, bryter mot eller tidigare har brutit mot dessa
                  villkor, andra, av Head2Rent, tillhandahållna villkor,
                  Head2Rents vid var tid gällande riktlinjer för användning
                  eller lagar och regler.
                </li>
                <li>
                  För att Head2Rent ska kunna tillhandahålla Tjänsten förutsätts
                  att Användare tillämpar minst samma säkerhet av
                  personuppgifter som vår integritetspolicy.
                </li>
              </ol>
            </div>
            <div className="term">
              <span className="term-title">Registrera konto hos Head2Rent</span>
              <ol>
                <li>
                  När ett konto registreras hos Head2Rent så måste fullständiga
                  och korrekta uppgifter tillhandahållas. Behörig företrädare
                  åtar sig att vara kontaktperson och att löpande uppdatera
                  informationen i det registrerade kontot så att denna
                  information är fullständig och korrekt.
                </li>
                <li>
                  Kontot är personligt för respektive Användare och den behöriga
                  företrädaren ansvarar för den aktivitet som sker med
                  användning av kontot. Det är därför viktigt att
                  inloggningsuppgifter förvaras på ett säkert sätt och inte
                  kommer i orätta händer. Om du vet eller har skäl att misstänka
                  att någon annan har tillgång till era inloggningsuppgifter,
                  bör du genast ändra dessa uppgifter via dina kontosidor.
                  Behörig företrädare för Användare åtar sig att omedelbart
                  underrätta Head2Rent om någon olovligen får tillgång till
                  eller använder kontot.
                </li>
              </ol>
            </div>
            <div className="term">
              <span className="term-title">Uthyrande parts skyldigheter</span>
              <ol>
                <li>
                  Det åligger den uthyrande parten att utställa en faktura till
                  den inhyrande parten enligt överenskommelse.
                </li>
                <li>
                  Det åligger den uthyrande parten att inneha relevanta
                  försäkringar för sin personal.
                </li>
                <li>
                  Det åligger den uthyrande parten att ansvara för
                  arbetsgivaravgifter och att dess personal får rätt ersättning
                  för det utförda arbetet.
                </li>
              </ol>
            </div>
            <div className="term">
              <span className="term-title">
                Att avsluta Head2Rents tjänster
              </span>
              <ol>
                <li>
                  Under den tid som Användare är anslutna till Tjänsten kommer
                  uthyrningen att omfattas av Tjänsten.
                </li>
                <li>
                  Användare som önskar att avsluta Head2Rents tjänster har en
                  uppsägning om noll (0) månader.
                </li>
              </ol>
            </div>
            <div className="term">
              <span className="term-title">Avstängning och blockering</span>
              <ol>
                <li>
                  Head2Rent förbehåller sig rätten att, med omedelbar verkan,
                  blockera eller stänga av en Användare som missbrukar,
                  manipulerar eller använder Tjänsten i strid med dessa villkor.
                </li>
              </ol>
            </div>
            <div className="term">
              <span className="term-title">Ändringar i villkoren</span>
              <ol>
                <li>
                  Head2Rent förbehåller sig rätten att göra ändringar i dessa
                  villkor.
                </li>
              </ol>
            </div>
            <div className="term">
              <span className="term-title">Head2Rents kontaktuppgifter</span>
              <ol style={{ listStyleType: "none" }}>
                <li>Head2Rent AB, 559400-5596</li>
                <li>Skånegatan 15</li>
                <li>267 33 Bjuv</li>
                <li>info@head2rent.se</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-image-container"></div>
    </div>
  );
};

export default TermsOfUse;
