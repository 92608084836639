import React, { useEffect, useState } from "react";
import Button from "./button";
import { useFormik } from "formik";
import companySchema from "../utils/schemas/companySchema";
import { getAllCountries } from "../api/countries";
import { getRegionsByCountryId } from "../api/region";
import { getCitiesByRegionId } from "../api/city";
import { getAllCategories } from "../api/categories";
import { useNavigate } from "react-router-dom";
import { signup as signupDispatch } from "../redux/thunks/auth";
import { connect } from "react-redux";
// import { FileUploader } from "react-drag-drop-files";
import { Link } from "react-router-dom";

// const fileTypes = ["PDF"];

const SignupComonent = ({ isLogin, setIsLogin, signup, token }) => {
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);
  // const [file, setFile] = useState(null);
  const [customErrors, setCustomErrors] = useState({});
  const navigate = useNavigate();

  // const handleRemoveFile = () => {
  //   setFile(null);
  // };

  const fetchCountries = async () => {
    const res = await getAllCountries();
    setCountries(res.data);
  };

  const fetchRegions = async (id) => {
    const res = await getRegionsByCountryId(id);
    setRegions(res.data);
  };

  const fetchCities = async (id) => {
    const res = await getCitiesByRegionId(id);
    setCities(res.data);
  };

  const fetchCategories = async () => {
    const res = await getAllCategories();
    if (res.status === 200) {
      setCategories(res.data);
    } else {
      setCategories([]);
    }
  };

  useEffect(() => {
    fetchCountries();
    fetchCategories();
  }, []);

  const handleRegister = async (values) => {
    const res = await signup(values);

    // if (res.status === 400) {
    //   setCustomErrors({ fileMessage: res.data.fileMessage });
    //   formik.setSubmitting(false);
    // }
    if (res.status === 403) {
      setCustomErrors({ msg: res.data.msg });
      formik.setSubmitting(false);
    }
    if (res.status === 201) {
      navigate(`/login/otp?comp=${res?.data?.company?.id}`);
    }
    return res;
  };

  const formik = useFormik({
    initialValues: {
      companyName: "",
      password: "",
      confirmPassword: "",
      companyNr: "",
      email: "",
      contactDetails: "",
      address: "",
      postNumber: "",
      region: "",
      city: "",
      country: "",
      category: "",
      billingAddress: "",
      termsAndConditions: false,
      union: false,
    },
    validationSchema: companySchema.companyRegister,
    onSubmit: (values) => {
      // const formData = new FormData();
      // for (const key in values) {
      //   formData.append(key, values[key]);
      // }
      // formData.append("registerFile", file);

      // handleRegister(formData);
      handleRegister(values);
    },
  });

  useEffect(() => {
    if (formik.values.country) {
      fetchRegions(formik.values.country);
    }
    if (formik.values.region) {
      fetchCities(formik.values.region);
    }
  }, [formik.values.country, formik.values.region]);

  // const handleChange = (file) => {
  //   setFile(file);
  //   setCustomErrors({ ...customErrors, fileMessage: "" });
  // };

  return (
    <div className="component-container">
      <div className="title">
        <span>Registrera bolag</span>
        <span className="line"></span>
      </div>
      <div className="form" style={{ backgroundColor: "black" }}>
        <form onSubmit={formik.handleSubmit}>
          <div className="company-name">
            <label>Bolagsnamn*</label>
            <input
              name="companyName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="text"
              placeholder="Bolagsnamn"
            />
            {formik.errors.companyName && formik.touched.companyName && (
              <span className="text-danger">{formik.errors.companyName}</span>
            )}
          </div>

          <div className="password">
            <label>Lösenord (Minst 8 tecken och 1 siffra)*</label>
            <input
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="password"
              placeholder="Lösenord"
            />
            {formik.errors.password && formik.touched.password && (
              <span className="text-danger">{formik.errors.password}</span>
            )}
          </div>
          <div className="password">
            <label>Bekräfta lösenord*</label>
            <input
              name="confirmPassword"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="password"
              placeholder="Lösenord"
            />
            {formik.errors.confirmPassword &&
              formik.touched.confirmPassword && (
                <span className="text-danger">
                  {formik.errors.confirmPassword}
                </span>
              )}
          </div>
          <div className="company-number">
            <label>Org. nummer*</label>
            <input
              name="companyNr"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="text"
              placeholder="Org. nummer"
            />
            {formik.errors.companyNr && formik.touched.companyNr && (
              <span className="text-danger">{formik.errors.companyNr}</span>
            )}
          </div>
          <div className="email">
            <label>E-post*</label>
            <input
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="text"
              placeholder="E-post"
            />
            {formik.errors.email && formik.touched.email && (
              <span className="text-danger">{formik.errors.email}</span>
            )}
          </div>
          <div className="telephone-number">
            <label>Telefon*</label>
            <input
              name="contactDetails"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="text"
              placeholder="Telefon"
            />
            {formik.errors.contactDetails && formik.touched.contactDetails && (
              <span className="text-danger">
                {formik.errors.contactDetails}
              </span>
            )}
          </div>
          <div className="address-postnr">
            <div className="address">
              <label>Adress*</label>
              <input
                name="address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                placeholder="Adress"
              />
              {formik.errors.address && formik.touched.address && (
                <span className="text-danger">{formik.errors.address}</span>
              )}
            </div>
            <div className="postnr">
              <label>Post nr*.</label>
              <input
                name="postNumber"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                placeholder="Post nr"
              />
              {formik.errors.postNumber && formik.touched.postNumber && (
                <span className="text-danger">{formik.errors.postNumber}</span>
              )}
            </div>
          </div>
          <div className="country">
            <label>Land*</label>
            <select
              name="country"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option value="">Land</option>
              {countries.map((country, index) => (
                <option key={index} value={country.id}>
                  {country.name}
                </option>
              ))}
            </select>
            {formik.errors.country && formik.touched.country && (
              <span className="text-danger">{formik.errors.country}</span>
            )}
          </div>
          <div className="region">
            <div className="lan">
              <label>Län*</label>
              <select
                name="region"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Län</option>
                {regions.map((region, index) => (
                  <option key={index} value={region.id}>
                    {region.name}
                  </option>
                ))}
              </select>
              {formik.errors.region && formik.touched.region && (
                <span className="text-danger">{formik.errors.region}</span>
              )}
            </div>
            <div className="state">
              <label>Stad*</label>
              <select
                name="city"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Stad</option>
                {cities.map((city, index) => (
                  <option key={index} value={city.id}>
                    {city.name}
                  </option>
                ))}
              </select>
              {formik.errors.city && formik.touched.city && (
                <span className="text-danger">{formik.errors.city}</span>
              )}
            </div>
          </div>
          <div className="business-area-category">
            <div className="business-area">
              <label>Kategori*</label>
              <select
                name="category"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Kategori</option>
                {categories.map((category, index) => (
                  <option key={index} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {formik.errors.category && formik.touched.category ? (
            <span className="text-danger">{formik.errors.category}</span>
          ) : null}
          <div className="billing-address">
            <label>Faktureringsadress*</label>
            <input
              name="billingAddress"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="text"
              placeholder="exempel@mail.se"
            />
            {formik.errors.billingAddress && formik.touched.billingAddress && (
              <span className="text-danger">
                {formik.errors.billingAddress}
              </span>
            )}
          </div>
          {/* <div className="fileUploaderBar">
            <label>F-skattesedel (Obligatoriskt för verifiering)*</label>
            <FileUploader
              multiple={false}
              handleChange={handleChange}
              name="file"
              types={fileTypes}
              classes={"drop"}
              label="Ladda upp filer, max filstorlek 10mb."
            />
            {file && (
              <div
                className=""
                style={{
                  display: "flex",
                  color: "white",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                Fil:
                <i className="fa fa-file fa-xl" aria-hidden="true"></i>
                {file.name.split(".pdf")[0]}
                <i
                  className="fa fa-times-circle fa-md action danger"
                  aria-hidden="true"
                  onClick={() => handleRemoveFile(file)}
                ></i>
              </div>
            )}

            {customErrors?.fileMessage && (
              <div className="text-danger">{customErrors.fileMessage}</div>
            )}
          </div> */}
          <div className="fileUpload">
            <div className="id06">
              <span>Kollektivavtal: </span>
              <input
                name="union"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="checkbox"
                value="union"
                id="union"
              />
            </div>
          </div>
          <div className="terms-of-agreement">
            <span>
              Har du läst våra &nbsp;
              <a
                href="/Anvandarvillkor"
                target="_blank"
                style={{ borderBottom: " 1px solid ", color: "#2f9486" }}
              >
                användarvillkor?
              </a>
            </span>
            <div className="terms-of-agreement-check">
              <input
                name="termsAndConditions"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="checkbox"
                value="haveRead"
                id="haveRead"
              />
              <span>
                Ja, jag har läst igenom och förstått användarvillkoren.
              </span>
            </div>
            {formik.errors.termsOfUse && formik.touched.termsOfUse && (
              <span className="text-danger">{formik.errors.termsOfUse}</span>
            )}
          </div>
          <div className="register-button">
            <Button
              className="login loading"
              title={
                !formik.isSubmitting ? (
                  "Registrera Bolag"
                ) : (
                  <i className="fas fa-circle-notch fa-spin"></i>
                )
              }
              type="submit"
              disabled={!formik.isValid || formik.isSubmitting}
            />
          </div>
          {customErrors?.msg && (
            <div className="text-danger">{customErrors.msg}</div>
          )}
        </form>
      </div>
      <div className="no-account">
        <span className="line"></span>
        <span className="no-account-text">Har du redan ett konto?</span>
        <div className="register-button">
          <Link to={`/login`}>
            <Button className="login" title={"Logga in här"} />
          </Link>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.login?.auth?.token,
});

const mapDispatchToProps = (dispatch) => ({
  signup: (data) => dispatch(signupDispatch(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupComonent);
