import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import reduxStore from "./redux/store";
import Loading from "./components/Dashboard/Loading";
import { ErrorBoundary } from "react-error-boundary";
const { store, persistor } = reduxStore();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <Suspense fallback={<Loading />}>
          <ErrorBoundary fallback={<div>Something went wrong</div>}>
            <App />
          </ErrorBoundary>
        </Suspense>
      </React.StrictMode>
    </PersistGate>
  </Provider>
);
