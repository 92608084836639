import React from "react";
import { Button } from "react-bootstrap";

const CustomButton = ({
  type,
  disabled,
  className,
  title,
  icon,
  handleClick,
  style,
}) => {
  return (
    <Button
      type={type}
      className={className}
      onClick={handleClick}
      disabled={disabled}
      style={style}
    >
      {title} {icon}
    </Button>
  );
};

export default CustomButton;
