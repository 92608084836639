import {
  GET_ALL_REQUESTS_SENT,
  GET_ALL_REQUESTS_RECIEVED,
  UPDATE_REQUESTS_SENT,
  DELETE_REQUESTS_SENT,
  UPDATE_REQUESTS_RECIEVED,
} from "../actionTypes";

const initialState = {
  notification: 0,
  requestsSentData: null,
  requestsRecievedData: [],
};

// eslint-disable-next-line
export default (state = initialState, action) => {
  if (!state) {
    return initialState;
  }
  switch (action.type) {
    case GET_ALL_REQUESTS_SENT: {
      return {
        ...state,
        requestsSentData: action.payload,
        requestsSent: action.payload.length,
        notification: Number(state.requestsSent),
      };
    }
    case UPDATE_REQUESTS_SENT: {
      return {
        ...state,
        requestsSentData: [...state.requestsSentData, action.payload],
      };
    }
    case DELETE_REQUESTS_SENT: {
      return {
        ...state,
        requestsSentData: state.requestsSentData.filter(
          (req) => req.id !== action.payload
        ),
      };
    }
    case GET_ALL_REQUESTS_RECIEVED: {
      return {
        ...state,
        requestsRecievedData: action.payload,
        requestsRecieved: action.payload.filter(
          (item) => item.status === "requested"
        ).length,
        notification: Number(state.requestsSent),
      };
    }
    case UPDATE_REQUESTS_RECIEVED: {
      return {
        ...state,
        requestsRecieved: state.requestsRecieved - 1,
      };
    }
    default:
      return state;
  }
};
