import React from "react";
import { Routes, Route } from "react-router-dom";
import AdminLogin from "../pages/Admin/Login";
// import ResetPassword from "../pages/Company/ResetPassword";
import TermsOfUse from "../pages/Company/TermsOfUse";

function AdminHomepage(props) {
  return (
    <Routes>
      <Route path="/login" element={<AdminLogin />} />
      {/* <Route exact path="/login/reset/:id" element={<ResetPassword />} /> */}
      {/* <Route exact path="/login/admin/reset/:id" element={<ResetPassword />} /> */}
      <Route path="/Anvandarvillkor" element={<TermsOfUse />} />
    </Routes>
  );
}

export default AdminHomepage;
