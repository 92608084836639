import {
  differenceInDays,
  differenceInCalendarDays,
  formatDistanceToNow,
} from "date-fns";

export const distanceToNow = (date) => {
  if (!date) return;
  let res = formatDistanceToNow(Date.parse(date), {
    addSuffix: true,
  });

  return res;
};

export const formatDate = (date) => {
  if (!date) return;
  const newDate = date?.split("T")[0].split("-");
  return `${newDate[2]}-${newDate[1]}-${newDate[0]}`;
};

export const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

export const calculateDays = (fromDate, toDate) => {
  let from = new Date(fromDate);
  let to = new Date(toDate);
  let now = new Date();
  let daysLeft = differenceInCalendarDays(to, now);

  let daysLeftToStart = differenceInCalendarDays(from, now);

  if (daysLeftToStart === 0) {
    return "Startar idag";
  } else if (daysLeftToStart > 0) {
    return "Ej startat";
  } else return `${daysLeft} dagar kvar`;
};

export const calculatePercentage = (fromDate, toDate) => {
  let from = new Date(fromDate);
  let to = new Date(toDate);
  let now = new Date();

  let totalDays = differenceInDays(to, from);

  let daysWorked = differenceInCalendarDays(now, from);

  let percentage = ((daysWorked / totalDays) * 100).toFixed(2);

  let daysLeftToStart = differenceInCalendarDays(now, from);

  if (daysLeftToStart < 0) {
    return `Startar om  ${daysLeftToStart * -1} dagar`;
  } else if (daysLeftToStart === 0) {
    return `Startar idag`;
  } else if (percentage > 100) {
    return "Har jobbat klart";
  } else return Number(percentage);
};

export const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = name + "=" + value + "; expires =" + date.toUTCString();
};

export const getCookie = (name) => {
  const value = "; " + document.cookie;
  const parts = value.split("; " + name + "=");
  if (parts.length === 2) {
    return parts.pop().split(";").shift();
  }
};
