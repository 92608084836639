import React from "react";
import LoginComponent from "../../components/login";

const Login = () => {
  return (
    <>
      <div className="image-container login-container">
        <div className="background b-g-background">
          <LoginComponent adminLogin={false} />
        </div>
      </div>
    </>
  );
};

export default Login;
