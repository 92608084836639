import axios from "axios";
import { getToken } from "../utils/helpers";
import { logout as logoutAction } from "../redux/actions/auth";
import reduxStore from "../redux/store";

const { store } = reduxStore();

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_HEAD2HUNT_API,
});

axiosInstance.interceptors.request.use(
  (request) => {
    const token = getToken();
    if (token) request.headers.Authorization = `Bearer ${token}`;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      store.dispatch(logoutAction());
      localStorage.setItem("token", undefined);
      window.location.replace("/login");
    }

    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use();

export default axiosInstance;
