import axios from "./axiosInstance";

export const getAllCountries = async () => {
  try {
    const res = await axios.get("/countries");
    return res;
  } catch (err) {
    return err.response;
  }
};
