import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../pages/Company/Login";
import ResetPassword from "../pages/Company/ResetPassword";
import TermsOfUse from "../pages/Company/TermsOfUse";
import VerifyOTP from "../pages/Company/VerifyOtp";
import SignUp from "../pages/Company/Signup";

function Homepage(props) {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<SignUp />} />
      <Route path="/login/otp" element={<VerifyOTP />} />
      <Route exact path="/login/reset/:id" element={<ResetPassword />} />
      <Route path="/Anvandarvillkor" element={<TermsOfUse />} />
    </Routes>
  );
}

export default Homepage;
