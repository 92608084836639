import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
const JobDetails = lazy(() => import("../pages/Market/JobDetails"));
const NSidebar = lazy(() => import("../components/Dashboard/NSidebar"));
const Details = lazy(() => import("../pages/Company/Details"));
const Calendar = lazy(() => import("../pages/Company/Calendar"));
const Notifications = lazy(() => import("../pages/Company/Notifications"));
const RequestsSent = lazy(() => import("../pages/Company/RequestsSent"));
const Settings = lazy(() => import("../pages/Company/Settings.jsx"));
const Overview = lazy(() => import("../pages/Employee/Overview"));
const Employees = lazy(() => import("../pages/Employee/Employees"));
const Register = lazy(() => import("../pages/Employee/Register"));
const Profile = lazy(() => import("../pages/Employee/Profile"));
const Edit = lazy(() => import("../pages/Employee/Edit"));
const Sent = lazy(() => import("../pages/Employee/Sent"));
const Incoming = lazy(() => import("../pages/Employee/Incoming"));
const Jobposts = lazy(() => import("../pages/Jobpost/Jobposts"));
const Create = lazy(() => import("../pages/Jobpost/Create"));
const JobpostDetails = lazy(() => import("../pages/Jobpost/Details"));
const JobpostEdit = lazy(() => import("../pages/Jobpost/Edit"));
const Exchanges = lazy(() => import("../pages/Market/Exchanges"));
const Jobs = lazy(() => import("../pages/Market/Jobs.jsx"));
const Page404 = lazy(() => import("../pages/page404"));
const Loading = lazy(() => import("../components/Dashboard/Loading"));

const links = [
  {
    to: "/dashboard/",
    label: "Startsida",
    icon: <i className="fa fa-file-text-o fa-lg" aria-hidden="true"></i>,
    sublinks: [],
  },
  {
    to: "/dashboard/anstallda/overview",
    label: "Personal",
    icon: <i className="fa fa-user-o fa-lg" aria-hidden="true"></i>,
    sublinks: [
      {
        to: "/dashboard/anstallda",
        label: "All personal",
      },
      {
        to: "/dashboard/anstallda/registrera-anstalld",
        label: "Registrera ny",
      },
      {
        to: "/dashboard/anstallda/incoming",
        label: "Inhyrd",
      },
      {
        to: "/dashboard/anstallda/sent",
        label: "Uthyrd",
      },
    ],
  },
  {
    to: "/dashboard/jobpost",
    label: "Projekt",
    icon: <i className="fa fa-address-card-o  fa-lg" aria-hidden="true"></i>,
    sublinks: [],
  },
  {
    to: "/dashboard/kalenderoversikt",
    label: "Kalenderöversikt",
    icon: <i className="fa fa-calendar-o  fa-lg" aria-hidden="true"></i>,
    sublinks: [],
  },
  {
    to: "/dashboard/notiser",
    label: "Notiser",
    icon: <i className="fa fa-bell-o  fa-lg" aria-hidden="true"></i>,
    sublinks: [],
  },
  {
    to: "/dashboard/settings",
    label: "Inställningar",
    icon: <i className="fa fa-cog  fa-lg" aria-hidden="true"></i>,
    sublinks: [],
  },
];

const profile = {
  image: "https://avatars.githubusercontent.com/u/11344593?v=4",
  name: "Juan ",
};

function Dashboard(props) {
  return (
    <div>
      <Suspense fallback={<Loading />}>
        <NSidebar
          logo={<i className="bx bxl-c-plus-plus"></i>}
          name="Head2Rent"
          profile={profile}
          links={links}
        >
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="/" element={<Details />} />
              <Route path="/kalenderoversikt" element={<Calendar />} />
              <Route path="/anstallda/overview" element={<Overview />} />
              <Route path="/anstallda" element={<Employees />} />
              <Route
                path="/anstallda/registrera-anstalld"
                element={<Register />}
              />
              <Route path="anstallda/employee/:id" element={<Profile />} />
              <Route path="anstallda/employee/edit/:id" element={<Edit />} />
              <Route path="anstallda/incoming" element={<Incoming />} />
              <Route path="anstallda/sent" element={<Sent />} />

              <Route path="/jobpost/" element={<Jobposts />} />
              <Route path="/jobpost/create" element={<Create />} />
              <Route path="/jobpost/:id" element={<JobpostDetails />} />
              <Route path="/jobpost/edit/:id" element={<JobpostEdit />} />

              <Route path="/exchange" element={<Exchanges />} />
              <Route path="/job-board/job/:id" element={<JobDetails />} />
              <Route path="/notiser" element={<Notifications />} />
              <Route path="/notiser/requestSent" element={<RequestsSent />} />
              <Route path="/settings" element={<Settings />} />

              <Route path="/*" element={<Page404 />} />
              <Route path="/job-board" element={<Jobs />} />
              <Route
                path="/job-board/job/:id"
                element={
                  <Suspense fallback={<p>Loading...</p>}>
                    <JobDetails />
                  </Suspense>
                }
              />
            </Routes>
          </Suspense>
        </NSidebar>
      </Suspense>
    </div>
  );
}

export default Dashboard;
