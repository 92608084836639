import {
  signup as signupApi,
  login as loginApi,
  verifyCompany as verifyCompanyApi,
} from "../../api/auth";
import {
  signup as signupAction,
  login as loginAction,
  registerEmployee as registerEmployeeAction,
  removeEmployee as removeEmployeeAction,
  logout as logoutAction,
} from "../actions/auth";

export const signup = (data) => async (dispatch, getState) => {
  try {
    const res = await signupApi(data);

    if (res.status === 200 || res.status === 201) {
      localStorage.setItem("token", res.data.token);
      dispatch(signupAction(res.data));
    }
    return res;
  } catch (err) {}
};

export const login = (data) => async (dispatch, getState) => {
  try {
    const res = await loginApi(data);
    if (res.status === 200) {
      localStorage.setItem("token", res.data.token);
      dispatch(loginAction(res.data));
    }
    return res;
  } catch (err) {}
};

export const verify = (data) => async (dispatch, getState) => {
  try {
    const loginAuthState = getState().login.auth;
    const res = await verifyCompanyApi(data);
    if (res.status === 200) {
      dispatch(
        loginAction({
          ...loginAuthState,
          company: { ...loginAuthState.company, verified: true },
        })
      );
    }
    return res;
  } catch (err) {}
};

export const registerEmployee = () => async (dispatch, getState) => {
  try {
    dispatch(registerEmployeeAction());
  } catch (error) {}
};

export const removeEmployee = () => async (dispatch, getState) => {
  try {
    dispatch(removeEmployeeAction());
  } catch (error) {}
};

export const logout = () => async (dispatch) => {
  try {
    localStorage.removeItem("token");
    dispatch(logoutAction());
  } catch (error) {}
};
