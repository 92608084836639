import axios from "./axiosInstance";

export const getAllCategories = async () => {
  try {
    const res = await axios.get("/categories");
    return res;
  } catch (err) {
    return err.response;
  }
};

export const createCategories = async (data) => {
  try {
    const res = await axios.post("/categories", data);
    return res;
  } catch (err) {
    return err.response;
  }
};

export const editCategory = async (data) => {
  try {
    const res = await axios.put(`/categories/${data.id}`, { name: data.name });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const deleteCategory = async (id) => {
  try {
    const res = await axios.delete(`/categories/${id}`);
    return res;
  } catch (err) {
    return err.response;
  }
};

export const getCategoryById = async (id) => {
  try {
    const res = await axios.get(`/categories/${id}`);
    return res;
  } catch (err) {
    return err.response;
  }
};
