import React from "react";
import LoginComponent from "../../components/login";

const AdminLogin = () => {
  return (
    <>
      <div className={"image-container login-container"}>
        <div className={"background b-g-background"}>
          <LoginComponent adminLogin={true} />
        </div>
      </div>
    </>
  );
};

export default AdminLogin;
