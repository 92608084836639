import axios from "./axiosInstance";

export const signup = async (data) => {
  try {
    const res = await axios.post("/companies/register", data);
    return res;
  } catch (err) {
    return err.response;
  }
};

export const login = async (data) => {
  try {
    let res;
    if (data?.type === "user") {
      res = await axios.post("/users/login", data);
    } else {
      res = await axios.post("/companies/login", data);
    }
    return res;
  } catch (err) {
    return err.response;
  }
};

export const verifyCompany = async (data) => {
  try {
    const res = await axios.post("/companies/verify", data);
    return res;
  } catch (err) {
    return err.response;
  }
};

export const resendOtp = async (id) => {
  try {
    const res = await axios.get(`/companies/resend-otp/${id}`);
    return res;
  } catch (err) {
    return err.response;
  }
};

export const forgotPassword = async (data) => {
  try {
    const res = await axios.post("/reset-password/forgot-password", data);
    return res;
  } catch (err) {
    return err.response;
  }
};

export const resetPassword = async (data) => {
  try {
    const res = await axios.post("/reset-password", data);
    return res;
  } catch (err) {
    return err.response;
  }
};

export const forgotAdminPassword = async (data) => {
  try {
    const res = await axios.post("/reset-password/admin/forgot-password", data);
    return res;
  } catch (err) {
    return err.response;
  }
};

export const resetAdminPassword = async (data) => {
  try {
    const res = await axios.post("/reset-password/admin", data);
    return res;
  } catch (err) {
    return err.response;
  }
};
