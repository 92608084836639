import axios from "./axiosInstance";

export const getAllCities = async () => {
  try {
    const res = await axios.get("/cities");
    return res;
  } catch (err) {
    return err.response;
  }
};

export const getCitiesByRegionId = async (id) => {
  try {
    const res = await axios.get(`/cities/region/${id}`);
    return res;
  } catch (err) {
    return err.response;
  }
};
