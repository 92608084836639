import React from "react";
import ResetPasswordComponent from "../../components/resetPassword";

const ResetPassword = () => {
  return (
    <>
      <div className={"image-container login-container"}>
        <div className={"background b-g-background"}>
          <ResetPasswordComponent />
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
