import React, { useState } from "react";
import { connect } from "react-redux";
import Button from "./button";
import { verify as verifyDispatch } from "../redux/thunks/auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resendOtp } from "../api/auth";

const VerifyOtp = ({ company, verify }) => {
  const [otp, setOtp] = useState("");
  const [otpErrors, setOtpErrors] = useState({});
  const [otpResentMsg, setOtpResentMsg] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const comp = searchParams.get("comp");

  const handleVerify = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const data = {
      id: company ? company.id : comp,
      otp,
    };
    const res = await verify(data);

    if (res.status === 200) {
      setIsSubmitting(false);

      navigate("/dashboard");
    } else {
      setIsSubmitting(false);
      setOtpErrors({ msg: res.data.msg });
    }
  };

  return (
    <div className="component-container">
      <div className="title">
        <span>Verifiera</span>
        <span className="line"></span>
      </div>
      <div className="form" style={{ backgroundColor: "black" }}>
        <form onSubmit={(e) => handleVerify(e)}>
          <div className="email">
            <label>Pinkod</label>
            <input
              type="text"
              placeholder="Pinkod"
              name="otp"
              onChange={(e) => {
                setOtpErrors({});
                setOtp(e.target.value);
              }}
            />
            {otpErrors.msg && (
              <span className="text-danger">{otpErrors.msg}</span>
            )}
          </div>
          <div className="login-button">
            <Button
              className="login"
              title={
                !isSubmitting ? (
                  "Verifiera"
                ) : (
                  <i className="fas fa-circle-notch fa-spin"></i>
                )
              }
              type="submit"
              disabled={otp.length !== 6 || isSubmitting ? true : false}
            />
          </div>
          <div className="forgot-password">
            <span
              onClick={async () => {
                const res = await resendOtp(company.id);

                if (res.status === 200) {
                  setOtpResentMsg("Pin-kod har skickats till din e-post igen");
                }
              }}
            >
              Skicka Pinkod igen
            </span>
          </div>
          {otpResentMsg && <span className="text-success">{otpResentMsg}</span>}
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  company: state?.login?.auth?.company,
});

const mapDispatchToProps = (dispatch) => ({
  verify: (data) => dispatch(verifyDispatch(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyOtp);
