import {
  LOGIN,
  LOGOUT,
  SIGNUP,
  REGISTER_EMPLOYEE,
  REMOVE_EMPLOYEE,
} from "../actionTypes";

const initialState = {
  isAuthenticated: false,
};

export default (state = initialState, action) => {
  if (!state) {
    return initialState;
  }
  switch (action.type) {
    case SIGNUP: {
      return {
        ...state,
        auth: action.payload,
        isAuthenticated: false,
      };
    }
    case LOGIN: {
      return {
        ...state,
        auth: action.payload,
        isAuthenticated: true,
      };
    }
    case LOGOUT: {
      return {
        ...state,
        auth: null,
        isAuthenticated: false,
      };
    }
    case REGISTER_EMPLOYEE: {
      return {
        ...state,
        auth: {
          company: {
            ...state.auth.company,
            nrOfEmployees: state.auth.company.nrOfEmployees + 1,
          },
        },
      };
    }
    case REMOVE_EMPLOYEE: {
      return {
        ...state,
        auth: {
          company: {
            ...state.auth.company,
            nrOfEmployees: state.auth.company.nrOfEmployees - 1,
          },
        },
      };
    }
    default:
      return state;
  }
};
