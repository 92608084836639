import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
const NSidebar = lazy(() => import("../components/Dashboard/NSidebar"));
const Categories = lazy(() => import("../pages/Admin/Categories"));
const CreateCategories = lazy(() => import("../pages/Admin/CreateCategories"));
const Certifications = lazy(() => import("../pages/Admin/Certifications"));
const CreateCertifications = lazy(() =>
  import("../pages/Admin/CreateCertifications")
);
const Companies = lazy(() => import("../pages/Admin/Companies"));
const CompanyDetails = lazy(() => import("../pages/Admin/CompanyDetails"));
const CreateUsers = lazy(() => import("../pages/Admin/CreateUsers"));
const EditCompanies = lazy(() => import("../pages/Admin/EditCompanies"));
const Users = lazy(() => import("../pages/Admin/Users"));
const Page404 = lazy(() => import("../pages/page404"));
const Loading = lazy(() => import("../components/Dashboard/Loading"));
const links = [
  {
    to: "",
    label: "Companies",
    icon: <i className="fa fa-file-text-o fa-lg" aria-hidden="true"></i>,
    sublinks: [],
  },
  {
    to: "categories",
    label: "Categories",
    icon: <i className="fa fa-list-ul" aria-hidden="true"></i>,
    sublinks: [
      {
        to: "categories/add",
        label: "Add Category",
      },
    ],
  },
  {
    to: "certifications",
    label: "Certifications",
    icon: <i className="fa fa-id-card-o fa-lg" aria-hidden="true"></i>,
    sublinks: [
      {
        to: "certifications/add",
        label: "Add Certifications",
      },
    ],
  },
  {
    to: "users",
    label: "User",
    icon: <i className="fa fa-user-o fa-lg" aria-hidden="true"></i>,
    sublinks: [
      {
        to: "users/add",
        label: "Add User",
      },
    ],
  },
];

function AdminDashboard(props) {
  return (
    <div>
      <Suspense fallback={<Loading />}>
        <NSidebar links={links}>
          <Routes>
            <Route path="/" element={<Companies />} />
            <Route path="/companies/edit/:id" element={<EditCompanies />} />
            <Route path="/companies/details/:id" element={<CompanyDetails />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/categories/add" element={<CreateCategories />} />
            <Route path="/certifications" element={<Certifications />} />
            <Route
              path="/certifications/add"
              element={<CreateCertifications />}
            />
            <Route path="/users" element={<Users />} />
            <Route path="/users/add" element={<CreateUsers />} />
            <Route path="/*" element={<Page404 />} />
          </Routes>
        </NSidebar>
      </Suspense>
    </div>
  );
}

export default AdminDashboard;
