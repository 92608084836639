import {
  LOGIN,
  LOGOUT,
  SIGNUP,
  REGISTER_EMPLOYEE,
  REMOVE_EMPLOYEE,
} from "../actionTypes";
//here come all the auth actions

export const login = (payload) => ({
  type: LOGIN,
  payload,
});

export const signup = (payload) => ({
  type: SIGNUP,
  payload,
});

export const logout = () => ({
  type: LOGOUT,
});

export const registerEmployee = () => ({
  type: REGISTER_EMPLOYEE,
});

export const removeEmployee = () => ({
  type: REMOVE_EMPLOYEE,
});
