//HERE COME ARE THE ACTION AS VARIABLES
export const LOGIN = "LOGIN";
export const SIGNUP = "SIGNUP";
export const LOGOUT = "LOGOUT";
export const REGISTER_EMPLOYEE = "REGISTER_EMPLOYEE";
export const REMOVE_EMPLOYEE = "REMOVE_EMPLOYEE";

//employees action types

export const GET_ALL_EMPLOYEES = "GET_ALL_EMPLOYEES";
export const GET_EMPLOYEE_BY_ID = "GET_EMPLOYEE_BY_ID";
export const CREATE_EMPLOYEE = "CREATE_EMPLOYEE";
export const EDIT_EMPLOYEE = "EDIT_EMPLOYEE";
export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";

//filter action types
export const SET_FROM_DATE_FILTER = "SET_FROM_DATE_FILTER";
export const SET_TO_DATE_FILTER = "SET_TO_DATE_FILTER";
export const SET_NAME_FILTER = "SET_NAME_FILTER";

//requests action types
export const GET_ALL_REQUESTS_SENT = "GET_ALL_REQUESTS_SENT";
export const UPDATE_REQUESTS_SENT = "UPDATE_REQUESTS_SENT";
export const DELETE_REQUESTS_SENT = "DELETE_REQUESTS_SENT";
export const GET_ALL_REQUESTS_RECIEVED = "GET_ALL_REQUESTS_RECIEVED";

//notification type
export const UPDATE_REQUESTS_RECIEVED = "UPDATE_REQUESTS_RECIEVED";
