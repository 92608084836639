import React from "react";
import VerifyOtp from "../../components/verifyOtp";

const VerifyOTP = () => {
  return (
    <>
      <div className={"image-container login-container"}>
        <div className={"background b-g-background"}>
          <VerifyOtp />
        </div>
      </div>
    </>
  );
};

export default VerifyOTP;
