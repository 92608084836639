import React from "react";
import SignupComponent from "../../components/signup";
const SignUp = () => {
  return (
    <>
      <div className={"image-container signup-container"}>
        <div className={"background g-b-background"}>
          <SignupComponent adminLogin={false} />
        </div>
      </div>
    </>
  );
};

export default SignUp;
