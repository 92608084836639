import Dashboard from "./views/dashboard";
import Homepage from "./views/homepage";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./style/index.scss";
import PrivateRoutes from "./utils/PrivateRoutes";
import AdminDashboard from "./views/adminDashboard";
import AdminHomepage from "./views/adminHomepage";
import Unauthorized from "./pages/unauthorized";

import setDefaultOptions from "date-fns/setDefaultOptions";
import { Toaster } from "sonner";
import { sv } from "date-fns/locale";
setDefaultOptions({ locale: sv });

const ROLES = {
  SUPERADMIN: 1,
  ADMIN: 2,
  COMPANY: 3,
};

function App() {
  return (
    <div className="App">
      <Toaster
        toastOptions={{
          style: { background: "#2f9486", color: "white" },
          className: "notification-toast",
          descriptionClassName: "notification-toast-description",
        }}
      />
      <BrowserRouter>
        <Routes>
          <Route
            element={
              <PrivateRoutes allowedRoles={[ROLES.ADMIN, ROLES.SUPERADMIN]} />
            }
          >
            <Route path="/admin/dashboard/*" element={<AdminDashboard />} />
          </Route>
          <Route element={<PrivateRoutes allowedRoles={[ROLES.COMPANY]} />}>
            <Route path="/dashboard/*" element={<Dashboard />} />
          </Route>
          <Route path="/*" element={<Homepage />} />
          <Route path="/admin/*" element={<AdminHomepage />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
