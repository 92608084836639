import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Unauthorized = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="unauthorized-container">
        <div className="title">Du har inte tillgång.</div>
        <div className="go-back-button">
          <Button className="go-back" onClick={() => navigate("/dashboard")}>
            Back
          </Button>
        </div>
      </div>
    </>
  );
};

export default Unauthorized;
