import { combineReducers } from "redux";

import authReducer from "../reducers/auth";
import employeeReducer from "../reducers/employee";
import requestsReducer from "../reducers/requests";

export default combineReducers({
  login: authReducer,
  employee: employeeReducer,
  requests: requestsReducer,
});
