import {
  GET_ALL_EMPLOYEES,
  GET_EMPLOYEE_BY_ID,
  CREATE_EMPLOYEE,
  EDIT_EMPLOYEE,
  DELETE_EMPLOYEE,
} from "../actionTypes";

const initialState = {};

export default (state = initialState, action) => {
  if (!state) {
    return initialState;
  }
  switch (action.type) {
    case GET_ALL_EMPLOYEES: {
      return {
        ...state,
        employee: action.payload,
      };
    }
    case GET_EMPLOYEE_BY_ID: {
      return {
        ...state,
        employee: action.payload,
      };
    }
    case CREATE_EMPLOYEE: {
      return {
        ...state,
        employee: action.payload,
      };
    }
    case EDIT_EMPLOYEE: {
      return {
        ...state,
        employee: action.payload,
      };
    }
    case DELETE_EMPLOYEE: {
      return {
        ...state,
        employee: action.payload,
      };
    }
    default:
      return state;
  }
};
