import React, { useState } from "react";
import Button from "./button";
import { useFormik } from "formik";
import companySchema from "../utils/schemas/companySchema";
import userSchema from "../utils/schemas/userSchema";
import { connect } from "react-redux";
import { login as loginDispatch } from "../redux/thunks/auth";
import { useNavigate, Link } from "react-router-dom";
import { forgotAdminPassword, forgotPassword } from "../api/auth";

const LoginComponent = ({ isLogin, setIsLogin, adminLogin, login }) => {
  const [customErrors, setCustomErrors] = useState({});
  const [forgotPasswordErrors, setForgotPasswordErrors] = useState("");
  const [emailSent, setEmailSent] = useState("");

  const navigate = useNavigate();
  const handleLogin = async (values) => {
    const data = { ...values, type: adminLogin ? "user" : "company" };
    const res = await login(data);

    if (res.status === 403 || res.status === 500) {
      setCustomErrors({ msg: "Ogiltiga uppgifter" });
      return;
    }
    if (!adminLogin) {
      if (!res.data?.company?.verified) {
        navigate(`/login/otp?comp=${res?.data?.company?.id}`);
        return;
      }
    }
    if (adminLogin) {
      navigate("/admin/dashboard");
    } else {
      navigate("/dashboard");
    }
    return res;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: !adminLogin
      ? companySchema.companyLogin
      : userSchema.userLogin,
    onSubmit: (values, { resetForm }) => {
      resetForm();
      handleLogin(values);
    },
  });

  return (
    <div className="component-container">
      <div className="title">
        <span>Logga In</span>
        <span className="line"></span>
      </div>
      <div className="form" style={{ backgroundColor: "black" }}>
        <form onSubmit={formik.handleSubmit}>
          <div className="email">
            <label>E-postadress</label>
            <input
              type="text"
              placeholder="E-postadress"
              name="email"
              value={formik.values.email || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.email && formik.touched.email && (
              <span className="text-danger">{formik.errors.email}</span>
            )}
          </div>
          <div className="password">
            <label>Lösenord</label>
            <input
              type="password"
              placeholder="Lösenord"
              name="password"
              value={formik.values.password || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.password && formik.touched.password && (
              <span className="text-danger">{formik.errors.password}</span>
            )}
          </div>
          {customErrors.msg && (
            <span className="text-danger">{customErrors.msg}</span>
          )}

          <div className="login-button">
            <Button
              type="submit"
              className="login"
              title={
                !formik.isSubmitting || customErrors ? (
                  "Logga In"
                ) : (
                  <i className="fas fa-circle-notch fa-spin"></i>
                )
              }
              disabled={
                !formik.isValid ||
                formik.isSubmitting ||
                !formik.values.email ||
                !formik.values.password
              }
            />
          </div>
          <div className="forgot-password">
            <span
              onClick={async () => {
                if (!formik.values.email) {
                  setForgotPasswordErrors("Vänligen fyll i e-post");
                  return;
                }
                let res;

                if (adminLogin) {
                  res = await forgotAdminPassword({
                    email: formik.values.email,
                  });
                } else {
                  res = await forgotPassword({
                    email: formik.values.email,
                  });
                }
                if (res.status === 200) {
                  setForgotPasswordErrors("");
                  setEmailSent("Ett mail har skickats till din e-post!");
                } else {
                  setEmailSent("");
                  setForgotPasswordErrors("Något blev fel, försök igen!");
                }
              }}
            >
              Glömt ditt lösenord?
            </span>
          </div>
          {forgotPasswordErrors && (
            <span className="text-warning">{forgotPasswordErrors}</span>
          )}
          {emailSent && <span className="text-success">{emailSent}</span>}
        </form>
      </div>
      {!adminLogin && (
        <div className="no-account">
          <span className="line"></span>
          <span className="no-account-text">Saknar du ett konto?</span>
          <div className="register-button">
            <Link to={`/register`}>
              <Button
                className="register"
                title={"Registrera dig här"}
                handleClick={() => {
                  setIsLogin(!isLogin);
                }}
              />
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  login: (data) => dispatch(loginDispatch(data)),
});

export default connect(null, mapDispatchToProps)(LoginComponent);
