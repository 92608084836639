import * as yup from "yup";

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const httpReg =
  /((https?):\/\/)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export default {
  companyRegister: yup.object().shape({
    companyName: yup.string().required("Företagsnamn krävs"),
    password: yup
      .string()
      .required("No password provided.")
      .min(8, "Lösenordet är för kort - bör vara minst 8 tecken"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Lösenord matchar inte"),
    companyNr: yup.string().required("Organisationsnummer krävs"),
    email: yup.string().required("E-post krävs").email("Ej giltlig e-post"),
    contactDetails: yup
      .string()
      .required("Telefonnummer krävs")
      .matches(phoneRegExp, "Please enter a valid Phone Number"),
    address: yup.string().required("Adress krävs"),
    postNumber: yup.string().required("Postnummer krävs"),
    region: yup.string().required("Län krävs"),
    country: yup.string().required("Land krävs"),
    city: yup.string().required("Stad krävs"),
    category: yup.string().required("Kategori krävs"),
    billingAddress: yup.string().required("Faktureringsadress krävs"),
    termsOfUse: yup
      .boolean()
      .oneOf([true], "Please confirm the terms of agreement"),
  }),
  companyLogin: yup.object().shape({
    email: yup
      .string()
      .required("E-post är ett obligatoriskt fält")
      .email("Ej giltlig e-post"),
    password: yup.string().required("Inget lösenord angett"),
  }),
  companyResetPassword: yup.object().shape({
    password: yup
      .string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 chars minimum."),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  }),
  companyEdit: yup.object().shape({
    companyName: yup.string().required("Company Name is required"),
    companyNr: yup.string().required("Company number is required"),
    email: yup.string().required("Email is a required field").email(),
    contactDetails: yup
      .string()
      .required()
      .matches(phoneRegExp, "Please enter a valid Phone Number"),
    address: yup.string().required("Address is required"),
    postNumber: yup.string().required("Zipcode is required"),
    region: yup.string().required("Region is required"),
    city: yup.string().required("City is required"),
    country: yup.string().required(),
    category: yup.string().required("Category is required"),
    nrOfEmployees: yup.number(),
  }),
};
