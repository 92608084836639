import { useFormik } from "formik";
import React, { useState } from "react";
import Button from "./button";
import companySchema from "../utils/schemas/companySchema";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetAdminPassword, resetPassword } from "../api/auth";

const ResetPasswordComponent = (props) => {
  const [searchParams] = useSearchParams();
  const [resetPasswordMsg, setResetPasswordMsg] = useState({});
  const navigate = useNavigate();

  const isAdmin = window.location.pathname.includes("admin");
  const email = searchParams.get("email");
  let token;
  if (isAdmin) {
    token = window.location.pathname.split("/").filter((item) => item)[3];
  } else {
    token = window.location.pathname.split("/").filter((item) => item)[2];
  }

  const handleResetPassword = async (values) => {
    const data = {
      email,
      token,
      password: values.password,
    };
    let res;
    if (isAdmin) {
      res = await resetAdminPassword(data);
    } else {
      res = await resetPassword(data);
    }

    if (res.status === 200) {
      setResetPasswordMsg({
        type: "success",
        msg: "Lösenordet har ändrats",
      });
      setTimeout(() => {
        if (isAdmin) {
          navigate("/admin/login");
        } else {
          navigate("/login");
        }
      }, 1000);
    } else {
      setResetPasswordMsg({
        type: "error",
        msg: "Något gick fel, försök igen",
      });
    }
  };
  const formik = useFormik({
    initialValues: {
      password: "",
      conmfirmPassword: "",
    },
    validationSchema: companySchema.companyResetPassword,
    onSubmit: (values) => {
      handleResetPassword(values);
    },
  });

  return (
    <div className="component-container">
      <div className="title">
        <span>Reset Password</span>
        <span className="line"></span>
      </div>

      <div className="form">
        <form onSubmit={formik.handleSubmit}>
          <div className="password">
            <label>Lösenord</label>
            <input
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Lösenord"
              name="password"
            />
            {formik.errors.password && formik.touched.password && (
              <span className="text-danger">{formik.errors.password}</span>
            )}
          </div>
          <div className="confirmPassword">
            <label>Bekräfta lösenord</label>
            <input
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Lösenord"
              name="confirmPassword"
            />
            {formik.errors.confirmPassword &&
              formik.touched.confirmPassword && (
                <span className="text-danger">
                  {formik.errors.confirmPassword}
                </span>
              )}
          </div>
          {resetPasswordMsg && (
            <span
              className={
                resetPasswordMsg.type === "error"
                  ? "text-danger"
                  : "text-success"
              }
            >
              {resetPasswordMsg.msg}
            </span>
          )}
          <div className="login-button">
            <Button
              type="submit"
              className="login"
              title={"Återställ lösenord"}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordComponent;
