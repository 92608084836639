import * as yup from "yup";

export default {
  userLogin: yup.object().shape({
    email: yup
      .string()
      .required("Email is a required field")
      .email("Ej giltlig e-post"),
    password: yup.string().required("No password provided."),
  }),
  userResetPassword: yup.object().shape({
    password: yup
      .string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 chars minimum."),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  }),
  userCreate: yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    email: yup
      .string()
      .required("Email is a required field")
      .email("Ej giltlig e-post"),
    password: yup
      .string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 chars minimum."),
    confirmPassword: yup
      .string()
      .required("Confirmation is required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  }),
};
